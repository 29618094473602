<template>
  <div class="navbar-header">
    <div class="d-flex">
      <!-- Logo Start -->
      <div class="navbar-brand-box">
        <router-link to="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="../assets/images/csms-dark-logo.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="../assets/images/csms-dark-logo.png" alt="" height="22" />
          </span>
        </router-link>

        <router-link to="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="../assets/images/csms-dark-logo.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="../assets/images/csms-dark-logo.png" alt="" height="22" />
          </span>
        </router-link>
      </div>
      <!-- Logo End -->

      <!-- Toggle Button Start -->
      <button type="button"
              class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              @click="toggleMenu">
        <i class="fa fa-fw fa-bars"></i>
      </button>
      <!-- Toggle Button End -->
    </div>

    <div class="d-flex">
      <!-- Language Select Start -->
      <b-dropdown variant="white" class="d-inline-block language-switch" right toggle-class="header-item">
        <template v-slot:button-content>
          <img class="me-1" :src="flag" alt="Header Language" height="16" />
          {{ $t(`common.${text}`) }}
        </template>
        <b-dropdown-item class="notify-item"
                         v-for="(entry, i) in languages"
                         :key="`Lang${i}`"
                         :value="entry"
                         @click="setLanguage(entry.language, entry.title, entry.flag)"
                         :class="{ active: lan === entry.language }">
          <img :src="`${entry.flag}`" alt="user-image" class="me-2" height="12" />
          <span class="align-middle">{{ $t(`common.${entry.title}`) }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <!-- Language Select End -->

      <!-- Notices Start -->
      <b-dropdown toggle-class="header-item noti-icon"
                  right
                  variant="white"
                  menu-class="dropdown-menu-lg dropdown-menu-end p-0">
        <template v-slot:button-content>
          <i class="fas fa-bullhorn"></i>
        </template>
        <div class="p-3 border-bottom bg-info-subtle">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="m-0 font-size-15">{{ $t("header.notices") }}</h5>
            </div>
            <div class="col-auto">
<!--              <a href="/system/notice/list" class="small fw-semibold text-decoration-underline">{{ $t("header.management") }}</a>-->
            </div>
          </div>
        </div>
        <simplebar style="max-height: 270px;">
          <template v-for="(item, index) of noticeItems">
            <a class="notification-item text-reset" href="#" :key="`notice_alert_${index}`" @click="noticeViewModal(item.noticeId)">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-grow-1 ms-1">
                  <p class="font-size-13 text-muted mb-1">
                    {{ item.created }}
                    <span class="badge bg-info badge-pill ms-2"
                          v-if="$_utils.inDays(item.created, 7)">New</span>
                  </p>
                  <h6 class="mb-0">{{ $_utils.textLengthOverCut(item.title, 30, null) }}</h6>
                </div>
              </div>
            </a>
          </template>
        </simplebar>
      </b-dropdown>
      <!-- Notices End -->

      <!-- Notifications Start -->
      <b-dropdown v-if="$_app.user.roles[0] == 'ADMIN'"
                  toggle-class="header-item noti-icon"
                  right
                  variant="white"
                  menu-class="dropdown-menu-lg dropdown-menu-end p-0">
        <template v-slot:button-content>
          <i class="fas fa-bell"></i>
<!--          <span class="noti-dot bg-danger"></span>-->
        </template>
        <div class="p-3 border-bottom bg-danger-subtle">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="m-0 font-size-15">{{ $t("header.notifications") }}</h5>
            </div>
            <div class="col-auto">
<!--              <a href="#" class="small fw-semibold text-decoration-underline">{{ $t("header.more") }}</a>-->
            </div>
          </div>
        </div>
        <simplebar style="max-height: 270px;">
          <template v-for="(item, index) in systemItems">
            <a class="notification-item text-reset" href="#" :key="`notification_alert_${index}`" @click="notificationViewModal(item.id)">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-grow-1 ms-1">
                  <p class="font-size-13 text-muted mb-1">
                    <span class="badge bg-danger badge-pill ms-2"
                          v-if="$_utils.inDays(item.created, 7)">New</span>
                  </p>
                  <h6 class="mb-0">{{ $_utils.textLengthOverCut(item.title, 30, null) }}</h6>
                </div>
              </div>
            </a>
          </template>
        </simplebar>
      </b-dropdown>
      <!-- Notifications End -->

      <!-- Template Setting Start -->
<!--      <div class="dropdown d-inline-block toggle-right">
        <button type="button"
                class="btn header-item noti-icon right-bar-toggle toggle-right"
                id="right-bar-toggle"
                @click="toggleRightSidebar">
          <feather type="settings" class="icon-sm toggle-right"></feather>
        </button>
      </div>-->
      <!-- Template Setting End -->

      <!-- Profile Start -->
      <b-dropdown variant="white"
                  right
                  toggle-class="header-item user text-start d-flex align-items-center"
                  class="dropdown d-inline-block"
                  menu-class="dropdown-menu-end pt-0">
        <template v-slot:button-content>
<!--          <img class="rounded-circle header-profile-user"
               src="@/assets/images/users/avatar-1.jpg"
               alt="Header Avatar" />-->
          <i class="uil uil-user-circle" style="font-size: 24px; color: dodgerblue"></i>
          <span class="ms-2 d-none d-sm-block user-item-desc">
            <span class="user-name">{{ $_app.user.email }}</span>
            <span class="user-sub-title">{{ $_app.user.roles[0] }}</span>
          </span>
        </template>

        <div class="p-3 bg-primary border-bottom mb-2">
          <!-- TODO: Token 에 이름 정보가 들어 오면 이름 노출 가능 -->
<!--          <h6 class="mb-0 text-white">Calvin</h6>-->
          <h6 class="mb-0 text-white">
<!--            {{ $_app.code.role[$_app.user.roles[0]] }}-->
          </h6>
          <p class="mb-0 font-size-11 text-white-50 fw-semibold">{{ $_app.user.email }}</p>
        </div>
        <router-link class="dropdown-item" to="/profile">
          <i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
          <span class="align-middle">{{ $t('header.profile' )}}</span>
        </router-link>

        <b-dropdown-divider></b-dropdown-divider>

        <a class="dropdown-item" href="javascript:void(0);" @click="logout()">
          <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
          <span class="align-middle">{{ $t('header.logout') }}</span>
        </a>
      </b-dropdown>
      <!-- Profile End -->

      <notice-view-modal ref="headerNoticeViewModal" :selected-id="selectNoticeId" @callback="getNoticeList" />
      <notification-view-modal ref="headerNotificationViewModal" :selected-id="selectNotificationId" @callback="getSystemItems" />
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import i18n from '@/i18n'
import { http } from "@/auth-api";
import NoticeViewModal from '@/views/pages/notice/NoticeViewModal.vue';
import NotificationViewModal from '@/views/pages/system/NotificationViewModal.vue';

export default {
  components: {
    simplebar, NoticeViewModal, NotificationViewModal
  },
  data () {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/japan.jpg'),
          language: 'jp',
          title: 'japanese'
        },
        {
          flag: require('@/assets/images/flags/korea.jpg'),
          language: 'ko',
          title: 'korean'
        }
        /*{
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'english'
        }*/
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      selectNoticeId: null,
      selectNotificationId: null,
      noticeItems: [],
      systemItems: []
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    }
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;

    this.getNoticeList();
    this.getSystemItems();
  },
  methods: {
    getNoticeList() {
      const vm = this;
      const _url = '/onmapi/view/Notice/list';

      http.post(_url, {
        page: 1,
        limit: 30,
        searchConditions: [
          { 'searchKey': 'contentType', 'searchValue': 'notice' },
          { 'searchKey': 'appCsmsType', 'searchValue': 'CSMS' }
        ],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.noticeItems = res.data.items;
      }).catch(err => {
        console.error('Notice List Error :: ', err)
      });
    },
    noticeViewModal(id) {
      const vm = this;
      vm.selectNoticeId = id;

      this.$nextTick(() => {
        this.$refs.headerNoticeViewModal.show();
      });
    },
    getSystemItems() {
      const vm = this;
      const _url = '/onmapi/view/SystemNotification/list';

      http.post(_url, {
        page: 1,
        limit: 30,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.systemItems = res.data.items;
      }).catch(err => {
        console.error('System Notification List Error :: ', err);
      });
    },
    notificationViewModal(id) {
      const vm = this;
      vm.selectNotificationId = id;

      this.$nextTick(() => {
        this.$refs.headerNotificationViewModal.show();
      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    logout() {
      const vm = this;
      vm.$_app.signOut();
      vm.$router.push( {path: '/login'} );
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  },
};
</script>
