<template>
  <Layout>
    <section class="csms-charge-box">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-8">
          <SummaryCard :title="$_formatter.convertedTitle('chargeBox', 'cbStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.total, divide: true },
                         { name: $t('common.uninstall'), value: infoItems.uninstall },
                         { name: $t('common.slow'), value: infoItems.uninstallSlow },
                         { name: $t('common.fast'), value: infoItems.uninstallFast, divide: true },
                         { name: $t('common.install'), value: infoItems.install },
                         { name: $t('common.slow'), value: infoItems.installSlow },
                         { name: $t('common.fast'), value: infoItems.installFast }
                       ]" />
        </div>
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('chargeBox', 'installCbStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.connectorTotal, divide: true },
                         { name: $t('chargeBox.charging'), value: infoItems.charging },
                         { name: $t('chargeBox.available'), value: infoItems.available },
                         { name: $t('chargeBox.checking'), value: infoItems.faulted }
                       ]" />
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-2" v-if="$_app.user.roles[0] === 'ADMIN'">
                      <label class="form-label" for="cb-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="cb-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <!-- // TODO: Select Box 형태로 변경 -->
                    <div class="col-xl-2">
                      <label class="form-label" for="cb-search-cp-name">{{ $t('common.cp') }}</label>
                      <input type="text" class="form-control" id="cb-search-cp-name"
                             v-model="searchForm.cpName" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="cb-search-id">{{ $t('chargeBox.chargeBoxId') }}</label>
                      <input type="text" class="form-control" id="cb-search-id"
                             v-model="searchForm.cbId" />
                    </div>
                    <div class="col-xl-1">
                      <label class="form-label" for="cb-search-charger-type">{{ $t('chargeBox.chargerType') }}</label>
                      <multiselect id="cb-search-charger-type"
                                   v-model="searchForm.chargerType"
                                   label="text" track-by="value"
                                   :options="chargerTypeOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="cb-search-charging-status">{{ $t('chargeBox.chargingStatus') }}</label>
                      <multiselect id="cb-search-charging-status"
                                   v-model="searchForm.status"
                                   label="text" track-by="value"
                                   :options="chargingStatusOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="cb-search-period">{{ $t('chargeBox.installDate') }}</label>
                      <div>
                        <date-picker class="w-100" v-model="searchForm.period" type="date" range
                                     :placeholder="$t('alert.selectDateRange')" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('chargeBox.noCb')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`chargeBox.${data.column}`) }}</span>
                  </template>

                  <template #cell(hqName)="data">
                    {{ data.item.chargePlace && data.item.chargePlace.hqBusiness ? data.item.chargePlace.hqBusiness.hqName : '-' }}
                  </template>
                  <template #cell(cpName)="data">
                    {{ data.item.chargePlace ? data.item.chargePlace.placeName : '-' }}
                  </template>
                  <template #cell(chargerType)="data">
                    {{ $_formatter.toLowerCaseString(data.item.chargerType) === 'fast' ? $t('common.fast') : $t('common.slow') }}
                  </template>
                  <template #cell(maxKw)="data">
                    <span>{{ data.item.maxKw }}</span>
                  </template>
                  <template #cell(isConnected)="data">
                    <span>{{ data.item.isConnected ? $t('common.connected') : $t('common.disconnected') }}</span>
                  </template>
                  <template #cell(connectorCount)="data">
                    {{ data.item.connectors.length }}
                  </template>
                  <template #cell(cbType)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span>{{ item.connectorType }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(connectorStatus)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span class="pe-2 " v-if="$_app.user.roles[0] === 'CP'">{{ item.connectorLinkedId }}</span>
                          <span class="pe-2" v-else><a class="text-decoration-underline" href="#" @click="connectorModal(item.id)">{{ item.connectorLinkedId }}</a></span>

                          [<span :class="$_formatter.cbStatusTextColor($_app.code.chargePointStatus[item.status] ?? item.status)">{{ $_app.code.chargePointStatus[item.status] ? item.status : '-' }}</span>]
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(installDate)="data">
                    {{ data.item.installationDate ? $_formatter.timestampToDate(data.item.installationDate) : '-' }}
                  </template>
                  <template #cell(log)="data">
<!--                    <b-button size="sm" variant="outline-secondary" @click="realLogViewModal(data.item.chargeBoxId)">
                      <i class="uil uil-database-alt me-2"></i>
                      <span>{{ $t('btn.realTime') }}</span>
                    </b-button>-->
                    <b-button size="sm" variant="outline-secondary" class="me-2" @click="openDayPopup(data.item)">
                      <i class="uil uil-database-alt me-2"></i>
                      <span>{{ $t('btn.daily') }}</span>
                    </b-button>
                    <b-button size="sm" variant="outline-secondary" @click="openRealModal(data.item)">
                      <i class="uil uil-database-alt me-2"></i>
                      <span>{{ $t('btn.realTime') }}</span>
                    </b-button>
                  </template>
                  <template #cell(etc)="data" v-if="$_app.user.roles[0] !== 'CP'">
                    <b-button size="sm" variant="warning" @click="edit(data.item.id)">
                      <i class="uil uil-edit me-2"></i>
                      <span>{{ $t('btn.edit') }}</span>
                    </b-button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <!-- Bottom Button Start -->
              <template v-if="$_app.user.roles[0] !== 'CP'">
                <div class="row">
                  <div class="col-xl-6"></div>
                  <div class="col-xl-6">
                    <div class="float-end">
                      <b-button variant="success" @click="create">
                        <i class="uil uil-plus me-2"></i>
                        <span>{{ $t('btn.create') }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Bottom Button End -->

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <create-modal ref="cbCreateModal" @callback="getItems" />
      <edit-modal ref="cbEditModal" :selected-id="selectedId" @callback="getItems" />
      <connector-edit-modal ref="connectorEditModal" :selected-id="selectedConnectorId" @callback="getItems" />
      <day-log-modal ref="dayLogViewModal" :log-info="logInfo" />
      <real-log-modal ref="realLogModal" :log-info="logInfo" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCard from '@/views/components/SummaryCard.vue';
import CreateModal from '@/views/pages/charge-box/CbCreateModal.vue';
import EditModal from '@/views/pages/charge-box/CbEditModal.vue';
import ConnectorEditModal from '@/views/pages/charge-box/ConnectorEditModal.vue';
import DayLogModal from '@/views/pages/system/DayLogViewModal.vue';
import RealLogModal from '@/views/pages/system/RealLogModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, SummaryCard,
    CreateModal, EditModal, ConnectorEditModal, DayLogModal, RealLogModal
  },
  data() {
    return {
      title: 'chargePoint',
      infoItems: {
        total: 0,
        uninstall: 0,
        uninstallSlow: 0,
        uninstallFast: 0,
        install: 0,
        installSlow: 0,
        installFast: 0,
        connectorTotal: 0,
        charging: 0,
        available: 0,
        faulted: 0
      },
      hqOptions: [],
      chargerTypeOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('common.slow'), value: 'SLOW' },
        { text: i18n.t('common.fast'), value: 'FAST' },
      ],
      chargingStatusOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('chargeBox.charging'), value: 'Charging' },
        { text: i18n.t('chargeBox.available'), value: 'Available' },
        { text: i18n.t('chargeBox.checking'), value: 'Else' }
      ],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        hqId: { text: i18n.t('common.total'), value: null },
        cpName: null,
        cbId: null,
        chargerType: { text: i18n.t('common.total'), value: null },
        status: { text: i18n.t('common.total'), value: null },
        period: null
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'cpName', label: '충전소명', class: 'text-center' },
        { key: 'chargeBoxName', label: '충전기명', class: 'text-center' },
        { key: 'chargeBoxId', label: '충전기 ID', class: 'text-center' },
        { key: 'chargerType', label: '충전 유형', class: 'text-center' },
        { key: 'maxKw', label: '충전 속도', class: 'text-center' },
        { key: 'connectorCount', label: '충전구 수', class: 'text-center' },
        { key: 'cbType', label: '충전기 유형', class: 'text-center' },
        { key: 'connectorStatus', label: '충전구 ID [상태]', class: 'text-center' },
        { key: 'isConnected', label: '접속 상태', class: 'text-center' },
        { key: 'installDate', label: '설치일', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' }
      ],
      items: null,
      selectedId: null,
      selectedConnectorId: null,
      popup: {},
      logInfo: {
        chargeBoxId: null,
        chargeBoxName: null
      }
    }
  },
  created() {
    const vm = this;

    if (this.$_app.user.roles[0] !== 'CP') {
      vm.fields.push({ key: 'etc', label: '', class: 'text-center' });
    }

    if (this.$_app.user.roles[0] === 'ADMIN') {
      vm.fields.push({ key: 'log', label: '', class: 'text-center' });
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();

    vm.showOverlay();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    async getItems() {
      const vm = this;

      await Promise.all([
        vm.getInfoItems(),
        vm.getListItems()
      ]);
      vm.hideOverlay();
    },
    getInfoItems() {
      const vm = this;
      const _url = '/onmapi/view/Summary/chargePoint';

      return new Promise((resolve, reject) => {
        http.get(_url).then(res => {
          const _item = res.data.item;

          vm.infoItems = {
            ...vm.infoItems,
            total: _item.totalCount,
            uninstall: _item.notInstalled.count,
            install: _item.installed.count,
          }

          if (_item.notInstalled) {
            _item.notInstalled.items.forEach(val => {
              if (val.chargerType === 'SLOW') {
                vm.infoItems.uninstallSlow = val.count;
              } else if (val.chargerType === 'FAST') {
                vm.infoItems.uninstallFast = val.count;
              }
            });
          }

          if (_item.installed) {
            _item.installed.items.forEach(val => {
              if (val.chargerType === 'SLOW') {
                vm.infoItems.installSlow = val.count;
              } else if (val.chargerType === 'FAST') {
                vm.infoItems.installFast = val.count;
              }
            });
          }

          if (_item.statusItems) {
            let elseCount = 0;
            _item.statusItems.forEach(val => {
              if (val.status === 'Available') {
                vm.infoItems.available = val.count;
              } else if (val.status === 'Charging') {
                vm.infoItems.charging = val.count;
              } else {
                elseCount = elseCount + val.count;
              }
            });
            vm.infoItems.faulted = elseCount;
          }

          vm.infoItems.connectorTotal = vm.infoItems.available + vm.infoItems.charging + vm.infoItems.faulted;

          resolve(res);
        }).catch(err => {
          console.error('Charge Box Summary Error :: ', err)
          reject(err);
        });
      });
    },
    getListItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/list';

      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        }).then(res => {
          vm.items = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;
          resolve(res);
        }).catch(err => {
          console.error('Charge Box List Error :: ', err)
          reject(err);
        });
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        hqId: { text: i18n.t('common.total'), value: null },
        cpName: null,
        cbId: null,
        chargerType: { text: i18n.t('common.total'), value: null },
        status: { text: i18n.t('common.total'), value: null },
        period: null
      };
      vm.searchConditions = [];

      vm.getListItems();
    },
    async search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqName', searchValue: vm.searchForm.hqId.text });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cpName)) {
        vm.searchConditions.push({ searchKey: 'placeName', searchValue: vm.searchForm.cpName });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cbId)) {
        vm.searchConditions.push({ searchKey: 'chargeBoxId', searchValue: vm.searchForm.cbId });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.chargerType.value)) {
        vm.searchConditions.push({ searchKey: 'chargerType', searchValue: vm.searchForm.chargerType.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.status.value)) {
        vm.searchConditions.push({ searchKey: 'status', searchValue: vm.searchForm.status.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
      }

      vm.showOverlay();
      vm.pagination.currentPage = 1;
      await vm.getListItems();
      vm.hideOverlay();

      /*if (this.$_utils.isEmpty(vm.searchForm.hqId.value)
          && this.$_utils.isEmpty(vm.searchForm.cpName)
          && this.$_utils.isEmpty(vm.searchForm.cbId)
          && this.$_utils.isEmpty(vm.searchForm.chargerType.value)
          && this.$_utils.isEmpty(vm.searchForm.status.value)
          && this.$_utils.isEmpty(vm.searchForm.period)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqName', searchValue: vm.searchForm.hqId.text });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cpName)) {
          vm.searchConditions.push({ searchKey: 'placeName', searchValue: vm.searchForm.cpName });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cbId)) {
          vm.searchConditions.push({ searchKey: 'chargeBoxId', searchValue: vm.searchForm.cbId });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.chargerType.value)) {
          vm.searchConditions.push({ searchKey: 'chargerType', searchValue: vm.searchForm.chargerType.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.status.value)) {
          vm.searchConditions.push({ searchKey: 'status', searchValue: vm.searchForm.status.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.period)) {
          vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
          vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
        }

        vm.pagination.currentPage = 1;
        vm.getListItems();
      }*/
    },
    create() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.cbCreateModal.show();
      });
    },
    edit(id) {
      const vm = this;

      vm.selectedId = id;

      vm.$nextTick(() => {
        this.$refs.cbEditModal.show();
      });
    },
    connectorModal(id) {
      const vm = this;

      vm.selectedConnectorId = id;

      vm.$nextTick(() => {
        this.$refs.connectorEditModal.show();
      });
    },
    openRealPopup(data) {
      const vm = this;

      const _chargeBoxId = data.chargeBoxId;
      const _uri = `/system/log/real?id=${_chargeBoxId}`;

      const _top = screen.height ? screen.height/2 : 720;
      const _left = screen.width ? screen.width/2 : 1280;
      const _width = screen.width ? screen.width/2 : 1280;
      const _height = screen.height ? screen.height/2 : 720;

      let attr = 'top=' + _top + ',left=' + _left  + ',width=' + _width + ',height=' + _height
          + ',resizable=no,status=no,menubar=no,toolbar=no,location=no';

      vm.popup[_chargeBoxId] = window.open(_uri, '_blank', attr);
      console.log('popup', vm.popup)
    },
    openDayPopup(data) {
      const vm = this;

      vm.logInfo = {
        chargeBoxId: data.chargeBoxId,
        chargeBoxName: data.chargeBoxName
      };

      this.$nextTick(() => {
        this.$refs.dayLogViewModal.show();
      });
    },
    openRealModal(data) {
      const vm = this;

      vm.logInfo = {
        chargeBoxId: data.chargeBoxId,
        chargeBoxName: data.chargeBoxName
      };

      this.$nextTick(() => {
        this.$refs.realLogModal.show();
      });
    }
  }
}
</script>
