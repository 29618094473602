<template>
  <Layout>
    <section class="csms-point">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-2" v-if="$_app.user.roles[0] === 'ADMIN'">
                      <label class="form-label" for="point-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="point-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   @select="getCpOptions()"
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="point-search-status">{{ $t('point.conversionStatus') }}</label>
                      <multiselect id="point-search-status"
                                   v-model="searchForm.conversionStatus"
                                   label="text" track-by="value"
                                   :options="statusOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-2">
                      <label class="form-label" for="point-search-member-name">{{ $t('point.memberName') }}</label>
                      <input type="text" class="form-control" id="point-search-member-name"
                             v-model="searchForm.memberName" />
                    </div>
                    <div class="col-xl-2" v-if="$_app.user.roles[0] !== 'CP'">
                      <label class="form-label" for="point-search-cp">{{ $t('common.cp') }}</label>
                      <multiselect id="point-search-cp"
                                   v-model="searchForm.cpId"
                                   label="text" track-by="value"
                                   :options="cpOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="ad-search-period">{{ $t('point.period') }}</label>
                      <div>
                        <date-picker type="date" range
                                     :placeholder="$t('alert.selectDateRange')"
                                     v-model="searchForm.period" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('point.noPoint')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`point.${data.column}`) }}</span>
                  </template>

                  <template #cell(checkbox)="data">
                    <input type="checkbox" class="form-check-input"
                           v-model="selectedPoint" :value="data.item.id"
                           :disabled="data.item.conversionStatus === 'Accu'">
                  </template>
                  <template #cell(conversionStatus)="data">
                    <span :class="data.item.conversionStatus === 'Accu' ? 'text-primary' : 'text-danger'">
                      {{ data.item.conversionStatus === 'Accu' ? $t('point.save') : $t('point.conversion') }}
                    </span>
                  </template>
                  <template #cell(hqName)="data">
                    {{ data.item.hqBusiness.hqName }}
                  </template>
                  <template #cell(cpName)="data">
                    {{ data.item.chargePlace.placeName }}
                  </template>
                  <template #cell(chargeFee)="data">
                    {{ $_utils.comma(data.item.chargeFee) }}
                  </template>
                  <template #cell(conversionDate)="data">
                    {{ !$_utils.isEmpty(data.item.conversionDate) ? data.item.conversionDate : '-' }}
                  </template>

                  <template #cell(etc)="data">
                    <b-button size="sm" variant="outline-warning" class="ps-3 pe-3"
                              @click="conversion(data.item.id)" v-if="data.item.conversionStatus === 'Accu'">
                      {{ $t('btn.transform') }}
                    </b-button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <!-- Bottom Button Start -->
      <div class="row mb-3">
        <div class="col-xl-12">
          <div class="float-end">
            <b-button variant="info" class="me-2" @click="csvDownload()">
              <i class="uil uil-file-download me-2"></i>
              <span>{{ $t('btn.csvDownload') }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- Bottom Button End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount
  },
  data() {
    return {
      title: 'managePoint',
      selectedPoint: [],
      hqOptions: [],
      statusOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('point.save'), value: 'Accu' },
        { text: i18n.t('point.conversion'), value: 'Changed' },
      ],
      cpOptions: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        hqId: { text: i18n.t('common.total'), value: null },
        conversionStatus: { text: i18n.t('common.total'), value: null },
        memberName: null,
        cpId: { text: i18n.t('common.total'), value: null },
        period: null
      },
      fields: [
        { key: 'checkbox', label: '', class: 'text-center' },
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'conversionStatus', label: '상태', class: 'text-center' },
        { key: 'tid', label: '결제 ID', class: 'text-center' },
        { key: 'memberName', label: '사용자명', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'cpName', label: '충전소명', class: 'text-center' },
        { key: 'connectionId', label: '포인트 연동 ID', class: 'text-center' },
        { key: 'chargeFee', label: '결제 금액', class: 'text-center' },
        { key: 'accuPoint', label: '발생 포인트', class: 'text-center' },
        { key: 'created', label: '적립일', class: 'text-center' },
        { key: 'conversionDate', label: '전환일', class: 'text-center' }
      ],
      items: null
    }
  },
  created() {
    const vm = this;

    if (this.$_app.user.roles[0] !== 'CP') {
      vm.fields.push({ key: 'etc', label: '', class: 'text-center' });
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });

        if (this.$_app.user.roles[0] === 'HQ') {
          vm.searchForm.hqId = vm.hqOptions.find(ele => ele.value === this.$_app.user.hqId);
          vm.getCpOptions();
        }
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getCpOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        http.post(_url, {
          page: 1,
          limit: 1000,
          searchConditions: [{ 'searchKey': 'hqId', 'searchValue': vm.searchForm.hqId.value }],
          sortCondition: { sortDirection: 'DESC' }
        }).then(res => {
          vm.searchForm.cpId = { text: i18n.t('common.total'), value: null };
          vm.cpOptions = [{ text: i18n.t('common.total'), value: null }];

          res.data.items.forEach(item => {
            vm.cpOptions.push({text: item.placeName, value: item.placeId});
          });
        }).catch(err => {
          console.error('Charge Place List Error :: ', err)
        });
      }
    },
    handleRangeClose() {
      const vm = this;
      vm.searchForm.period = null;
    },
    reset() {
      const vm = this;

      vm.hqOptions = [];
      vm.cpOptions = [];

      vm.searchForm = {
        hqId: { text: i18n.t('common.total'), value: null },
        conversionStatus: { text: i18n.t('common.total'), value: null },
        memberName: null,
        cpId: { text: i18n.t('common.total'), value: null },
        period: null
      };
      vm.searchConditions = [];

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.conversionStatus.value)) {
        vm.searchConditions.push({ searchKey: 'conversionStatus', searchValue: vm.searchForm.conversionStatus.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
        vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cpId.value)) {
        vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cpId.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD 00:00:00') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD 23:59:59') });
      }

      vm.pagination.currentPage = 1;
      vm.getItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.hqId.value)
          && this.$_utils.isEmpty(vm.searchForm.conversionStatus.value)
          && this.$_utils.isEmpty(vm.searchForm.memberName)
          && this.$_utils.isEmpty(vm.searchForm.cpId.value)
          && this.$_utils.isEmpty(vm.searchForm.period)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.conversionStatus.value)) {
          vm.searchConditions.push({ searchKey: 'conversionStatus', searchValue: vm.searchForm.conversionStatus.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
          vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cpId.value)) {
          vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cpId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.period)) {
          vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD 00:00:00') });
          vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD 23:59:59') });
        }

        vm.pagination.currentPage = 1;
        vm.getItems();
      }*/
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/MemberPoint/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Point List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getItems();
      }
    },
    conversion(id) {
      const vm = this;

      Swal.fire({
        title: i18n.t('alert.confirmConversion'),
        showCancelButton: true,
        confirmButtonText: i18n.t('btn.ok'),
        cancelButtonText: i18n.t('btn.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          const _url = `/onmapi/view/MemberPoint/Change/${id}`;

          http.patch(_url).then(res => {
            if (res) {
              Swal.fire({
                icon: 'info',
                title: i18n.t('alert.converted'),
                confirmButtonText: i18n.t('btn.close')
              });
              vm.getItems();
            }
          }).catch(err => {
            console.error('Member Point Conversion Error :: ', err)

            Swal.fire({
              icon: 'error',
              title: i18n.t('alert.error'),
              confirmButtonText: i18n.t('btn.close')
            });
          });
        }
      });
    },
    csvDownload() {
      const vm = this;

      // File Name Setting
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const dd = String(today.getDate()).padStart(2, '0');
      const formattedDate = `${yyyy}${mm}${dd}`;
      const fileName = `point_${formattedDate}.csv`;

      if (vm.selectedPoint.length < 1) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.selectPoint'),
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmDownload'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            const params = {
              ids: vm.selectedPoint
            };
            const queryParams = new URLSearchParams(params).toString();
            const _url = `/onmapi/view/MemberPoint/csv?${queryParams}`;

            http.get(_url, {
              responseType: 'blob'
            }).then(res => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }).catch((err) => {
              console.error('CSV Download Error !!', err)

              Swal.fire({
                icon: 'error',
                title: i18n.t('alert.error'),
                confirmButtonText: i18n.t('btn.close')
              });
            });
          }
        });
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
