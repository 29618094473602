<template>
  <Layout>
    <section class="csms-settlement-PG">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Area Start -->
      <div class="row">
        <div class="col-xl">
          <SummaryCardwithSlot :value="infoItems.totalCount" :unit="$t('unit.case').toString()">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">결제 건수</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-credit-card-multiple-outline font-size-26" />
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl">
          <SummaryCardwithSlot :value="infoItems.totalAmount" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">총 매출액</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-calculator font-size-26" />
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl">
          <SummaryCardwithSlot :value="infoItems.totalCommission" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">수수료(VAT 포함)</span>
            </template>
            <template v-slot:icon>
              <i class="uil uil-receipt font-size-26" />
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl">
          <SummaryCardwithSlot :value="infoItems.totalSettleExpectedAmount" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">정산 예정 금액</span>
            </template>
            <template v-slot:icon>
              <span class="badge rounded-pill badge-soft-danger font-size-12">정산 예정</span>
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl">
          <SummaryCardwithSlot :value="infoItems.totalSettleCompletedAmount" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">정산 완료 금액</span>
            </template>
            <template v-slot:icon>
              <span class="badge rounded-pill badge-soft-primary font-size-12">정산 완료</span>
            </template>
          </SummaryCardwithSlot>
        </div>
      </div>
      <!-- Summary Area End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <!-- // Search Input -->
                <div class="col-xl-10">
                  <div class="row">
                    <div class="col-xl-4">
                      <label class="form-label" for="settlement-search-status">상태</label>
                      <div>
                        <b-button :variant="searchForm.status === 'All' ? 'outline-primary' : 'outline-secondary'" class="me-2" @click="onChangeStatus('All')">전체</b-button>
                        <b-button :variant="searchForm.status === 'expected' ? 'outline-primary' : 'outline-secondary'" class="me-2" @click="onChangeStatus('expected')">정산 예정</b-button>
                        <b-button :variant="searchForm.status === 'completed' ? 'outline-primary' : 'outline-secondary'" class="me-2" @click="onChangeStatus('completed')">정산 완료</b-button>
                        <b-button :variant="searchForm.status === 'waiting' ? 'outline-primary' : 'outline-secondary'" @click="onChangeStatus('waiting')">정산 대기</b-button>
                      </div>

                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="history-search-period">정산예정일</label>
                      <div>
                        <date-picker class="w-100" v-model="searchForm.period" type="date" range
                                     :placeholder="$t('alert.selectDateRange')" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-2" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">

                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>

                <div class="col text-end">
                  {{ $t('settlement.unit') }}
                </div>

                <!-- // Total Count -->
                <div class="col-auto me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle text-center" bordered hover
                         thead-class="align-middle"
                         :fields="fields" :items="items">

                  <template #cell(status)="data">
                    <span v-if="data.item.status === 'expected'" class="badge rounded-pill badge-soft-danger font-size-12">
                      정산 예정
                    </span>
                    <span v-else-if="data.item.status === 'completed'" class="badge rounded-pill badge-soft-primary font-size-12">
                      정산 완료
                    </span>
                    <span v-else class="badge rounded-pill badge-soft-secondary font-size-12">
                      정산 대기
                    </span>
                  </template>
                  <template #cell(chargeFee)="data">
                    {{ $_utils.comma(data.item.chargeFee) }}
                  </template>
                  <template #cell(commission)="data">
                    {{ $_utils.comma(data.item.commission) }}
                  </template>
                  <template #cell(commissionVat)="data">
                    {{ $_utils.comma(data.item.commissionVat) }}
                  </template>
                  <template #cell(settleExpectedAmount)="data">
                    {{ $_utils.comma(data.item.settleExpectedAmount) }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCardwithSlot from "@/views/components/SummaryCardwithSlot.vue";

export default {
  name: "SettlementListPG",
  components: { Layout, PageHeader, SummaryCardwithSlot, PerPageSelector, TotalCount },
  data() {
    return {
      title: 'manageSettlementPG',
      infoItems: {
        totalCount: 0,
        totalAmount: 0,
        totalCommission: 0,
        totalSettleExpectedAmount: 0,
        totalSettleCompletedAmount: 0,
      },
      searchForm: {
        status: 'All',
        period: [new Date(this.$moment().startOf('month')), new Date(this.$moment().endOf('month'))]
      },
      searchConditions: [],
      sortDirection: 'ASC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      fields: [
        { key: 'rnum', label: '' },
        { key: 'tid', label: '결제ID' },
        { key: 'placeName', label: '층전소명' },
        { key: 'status', label: '정산상태' },
        { key: 'chargeFee', label: '결제금액(A)' },
        { key: 'commission', label: '수수료(B)' },
        { key: 'commissionVat', label: '수수료 VAT(C)' },
        { key: 'settleExpectedAmount', label: '정산 예정 금액( A-B-C )' },
        { key: 'settleExpectedDate', label: '정산 예정일' },
        { key: 'settleCompletedDate', label: '정산 완료일' },
      ],
      items: null,
      isDev: true // TODO : delete after development
    }
  },
  mounted() {
    const vm = this;
    vm.search();

    if (vm.isDev) {
      Swal.fire({
        icon: 'warning',
        title: '기획중인 화면',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  },
  methods: {
    ...loadingMethods,
    reset() {
      const vm = this;

      vm.searchForm = {
        status: 'All',
        period: [new Date(this.$moment().startOf('month')), new Date(this.$moment().endOf('month'))]
      };
      vm.searchConditions = [];
      vm.infoItems = {
        totalCount: 0,
        totalAmount: 0,
        totalCommission: 0,
        totalSettleExpectedAmount: 0,
        totalSettleCompletedAmount: 0,
      };

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (vm.searchForm.status !== 'All') {
        vm.searchConditions.push({ searchKey: 'status', searchValue: vm.searchForm.status });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
      }

      vm.pagination.currentPage = 1;

      vm.getItems();
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/Settlement/Spocha/list';

      try {
        vm.showOverlay();

        const res = await http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        });

        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;

        const summary = res.data.summary;
        vm.infoItems = {
          totalCount: summary.totalCount,
          totalAmount: summary.totalAmount,
          totalCommission: summary.totalCommission,
          totalSettleExpectedAmount: summary.totalSettleExpectedAmount,
          totalSettleCompletedAmount: summary.totalSettleCompletedAmount,
        };

      } catch (error) {
        console.error('Settlement Spocha List Error :: ', error);

      } finally {
        vm.hideOverlay();
      }
    },
    onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.getItems();
      }
    },
    onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.getItems();
      }
    },
    onChangeStatus(status) {
      const vm = this;

      vm.searchForm = { ...vm.searchForm, status }


    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>