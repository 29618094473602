<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ modalTitle }}</div>
    </template>

    <div class="row" ref="modalContent">
      <div class="col-xl-12">
        <b-overlay v-if="items === null" :show="true" variant="dark" opacity="1" rounded="sm" style="height: 92vh">
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="white" style="width: 2.5rem; height: 2.5rem;" />
            </div>
          </template>
        </b-overlay>

        <div v-else class="card mb-0">
          <div class="card-body bg-black text-white">

            <div v-if="items.length > 0">
              <div v-for="item in items" :key="item.id">
                <span class="ct-padding-right-100">{{ item.created }}</span>
                <span class="ct-padding-right-100">[{{ item.chargeBoxId }}]</span>
                <span class="ct-padding-right-100">[{{ item.msgType }}]</span>
                <span>{{ item.msg }}</span>
              </div>
            </div>
            <div v-else style="height: 92vh">
              <span>{{ $t('chargeBox.noLog') }}</span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="secondary" class="float-end w-xs p-1" @click="close">닫기</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';

export default {
  props: {
    logInfo: Object
  },
  computed: {
    modalTitle() {
      const boxName = this.logInfo.chargeBoxName;
      const boxId = this.logInfo.chargeBoxId;
      return `Log : ${boxName} (${boxId})`;
    }
  },
  data() {
    return {
      visible: false,
      searchConditions: [],
      pageLimit: 500,
      items: null,
      realLog: null,
      count: 0
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.items = null;
      vm.realLog = setInterval(vm.getItems, 3000);

      vm.visible = true;

      this.$nextTick(() => {

        const modalContent = this.$refs.modalContent;
        modalContent.scrollTop = modalContent.scrollHeight;
        this.$refs.modalContent.addEventListener('scroll', this.detectBottom);
      });
    },
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/OcppLog/logViewList';

      vm.searchConditions = [
        { searchKey: 'chargeBoxId', searchValue: this.logInfo.chargeBoxId }
      ];

      http.post(_url, {
        page: 1,
        limit: vm.pageLimit,
        searchConditions: vm.searchConditions
      }).then(res => {
        vm.items = res.data.items;
        this.$nextTick(() => {
          const modalContent = this.$refs.modalContent;
          modalContent.scrollTop = modalContent.scrollHeight;
        });
      }).then(res => {
        if (vm.count === 0 && vm.items.length > 0) {
          // window.scrollTo({ top: 99999, behavior: 'instant'});
          vm.count = 1;
        }
      }).catch(err => {
        console.error('Log List Error :: ', err)
      });
    },
    close() {
      const vm = this;
      clearInterval(vm.realLog);

      vm.items = null;
      vm.count = 0;
      vm.visible = false;

      this.$refs.modalContent.removeEventListener('scroll', this.detectBottom);
    },
    detectBottom() {
      // let scrollTop = document.documentElement.scrollTop;
      // let innerHeight = window.innerHeight;
      // let scrollHeight = document.body.scrollHeight;
      //
      // if (scrollTop + innerHeight >= scrollHeight) {
      //   return true;
      // } else {
      //   window.scrollTo({ top: scrollTop, behavior: 'instant'});
      //   return false;
      // }

      const modalContent = this.$refs.modalContent;
      const scrollTop = modalContent.scrollTop;
      const scrollHeight = modalContent.scrollHeight;
      const clientHeight = modalContent.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        return true;
      } else {
        modalContent.scrollTop = scrollTop;
        return false;
      }
    }
  }
}
</script>
