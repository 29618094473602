<template>
  <Layout>
    <section class="csms-charge-history">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-3">
          <SummaryCardWithSlot :value="infoItems.paymentCount" :unit="$t('unit.case').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('history.paymentCount') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-credit-card-outline font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
        <div class="col-xl-3">
          <SummaryCardWithSlot :value="infoItems.cancelCount" :unit="$t('unit.case').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('history.cancelCount') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-credit-card-off-outline font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
        <div class="col-xl-3">
          <SummaryCardWithSlot :value="infoItems.paymentPrice" :unit="$t('unit.money').toString()">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('history.paymentPrice') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-calculator font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
        <div class="col-xl-3">
          <SummaryCardWithSlot :value="infoItems.chargeEnergy" unit="kWh">
            <template v-slot:title>
              <span class="text-black-50 pe-1">{{ $t('common.today') }}</span>
              <span class="fw-bold">{{ $t('history.chargeEnergy') }}</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-ev-station font-size-26" />
            </template>
          </SummaryCardWithSlot>
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="history-search-tid">{{ $t('history.tid') }}</label>
                      <input type="text" class="form-control" id="history-search-tid"
                             v-model="searchForm.tid" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="history-search-cp">{{ $t('history.placeName') }}</label>
                      <multiselect id="history-search-cp"
                                   label="text" track-by="value"
                                   v-model="searchForm.cp"
                                   :options="cpOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="history-search-member-name">{{ $t('history.memberName') }}</label>
                      <input type="text" class="form-control" id="history-search-member-name"
                             v-model="searchForm.memberName" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="history-search-period">{{ $t('history.authPeriod') }}</label>
                      <div>
                        <date-picker class="w-100" v-model="searchForm.period" type="date" range
                                     :placeholder="$t('alert.selectDateRange')" :clearable="false" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('history.noHistory')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`history.${data.column}`) }}</span>
                  </template>

                  <template #cell(tid)="data">
                    {{ !$_utils.isEmpty(data.item.tid) ? data.item.tid : '-' }}
                  </template>
                  <template #cell(chargeEnergyKwh)="data">
                    {{ $_utils.comma(data.item.chargeEnergyKwh) }}
                  </template>
                  <template #cell(configKey)="data">
                    {{ $_formatter.chargingConfigTypeName(data.item.configKey) }}
                  </template>
                  <template #cell(beforeDiscountChargeFee)="data">
                    {{ $_utils.comma(data.item.beforeDiscountChargeFee) }}
                  </template>
                  <template #cell(chargeFee)="data">
                    {{ $_utils.comma(data.item.chargeFee) }}
                  </template>
                  <template #cell(paymentName)="data">
                    <span v-if="data.item.paymentMethod || data.item.appCardName">
                      {{ `${data.item.paymentMethod ?? '-'} | ${data.item.appCardName ?? '-'}` }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template #cell(authDate)="data">
                    {{ !$_utils.isEmpty(data.item.authDate) ? $_formatter.timestampToDate(data.item.authDate) : '-' }}
                  </template>
                  <template #cell(chargeTime)="data">
                    {{ data.item.chargeTime ? `${ data.item.startTimestamp.substring(11) } ~ ${ data.item.stopTimestamp.substring(11) } (${ data.item.chargeTime })` : '-' }}
                  </template>
                  <template #cell(memberPlanType)="data">
                    {{ $_formatter.conversionPlanName(data.item.memberPlanType) }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCardWithSlot from '@/views/components/SummaryCardwithSlot.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, SummaryCardWithSlot
  },
  data() {
    return {
      title: 'chargeHistory',
      infoItems: {
        paymentCount: 0,
        cancelCount: 0,
        paymentPrice: 0,
        chargeEnergy: 0
      },
      cpOptions: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        tid: null,
        cp: { text: i18n.t('common.total'), value: null },
        memberName: null,
        period: null
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'tid', label: '결제 ID', class: 'text-center' },
        { key: 'placeName', label: '충전소명', class: 'text-center' },
        { key: 'chargeBoxName', label: '충전기명', class: 'text-center' },
        { key: 'chargeBoxId', label: '충전기 ID', class: 'text-center' },
        { key: 'configKey', label: '충전 요청 유형', class: 'text-center' },
        { key: 'configValue', label: '충전 요청 값', class: 'text-center' },
        { key: 'chargeEnergyKwh', label: '충전 용량', class: 'text-center' },
        { key: 'beforeDiscountChargeFee', label: '충전 금액', class: 'text-center' },
        { key: 'discountRate', label: '할인률', class: 'text-center' },
        { key: 'chargeFee', label: '결제 금액', class: 'text-center' },
        { key: 'paymentName', label: '결제 수단', class: 'text-center' },
        { key: 'authDate', label: '승인일', class: 'text-center' },
        { key: 'chargeTime', label: '충전 시간', class: 'text-center' },
        { key: 'memberName', label: '사용자명', class: 'text-center' },
        { key: 'memberPlanType', label: '회원 플랜', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.showOverlay();
    vm.getCpOptions();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getCpOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.cpOptions = [{ text: i18n.t('common.total'), value: null }];

      http.post(_url, {
        page: 1,
        limit: 1000,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        res.data.items.forEach(item => {
          vm.cpOptions.push({ text: item.placeName, value: item.placeId });
        });
      }).catch(err => {
        console.error('Charge Place List Error :: ', err)
      });
    },
    async getItems() {
      const vm = this;

      await Promise.all([
        //vm.getInfoItems(),
        vm.getListItems()
      ]);
      vm.hideOverlay();
    },
    async reset() {
      const vm = this;

      vm.searchForm = {
        tid: null,
        cp: { text: i18n.t('common.total'), value: null },
        memberName: null,
        period: null
      };
      vm.searchConditions = [];

      vm.showOverlay();
      await vm.getListItems();
      vm.hideOverlay();
    },
    async search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.tid)) {
        vm.searchConditions.push({ searchKey: 'tid', searchValue: vm.searchForm.tid });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cp.value)) {
        vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cp.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
        vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.period)) {
        vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
        vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
      }

      vm.pagination.currentPage = 1;
      vm.showOverlay();
      await vm.getListItems();
      vm.hideOverlay();

      /*if (this.$_utils.isEmpty(vm.searchForm.tid)
          && this.$_utils.isEmpty(vm.searchForm.cp.value)
          && this.$_utils.isEmpty(vm.searchForm.memberName)
          && this.$_utils.isEmpty(vm.searchForm.period)) {
        await Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.tid)) {
          vm.searchConditions.push({ searchKey: 'tid', searchValue: vm.searchForm.tid });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cp.value)) {
          vm.searchConditions.push({ searchKey: 'placeId', searchValue: vm.searchForm.cp.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
          vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.period)) {
          vm.searchConditions.push({ searchKey: 'startDate', searchValue: this.$moment(vm.searchForm.period[0]).format('YYYY-MM-DD') });
          vm.searchConditions.push({ searchKey: 'endDate', searchValue: this.$moment(vm.searchForm.period[1]).format('YYYY-MM-DD') });
        }

        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }*/
    },
    getListItems() {
      const vm = this;
      const _url = '/onmapi/view/Payment/order/list';

      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        }).then(res => {
          vm.items = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;

          vm.infoItems = {
            paymentCount: res.data.summary.transactionCount,
            cancelCount: 0,
            paymentPrice: res.data.summary.chargeFee,
            chargeEnergy: res.data.summary.chargeEnergyKwh
          };

          resolve(res);
        }).catch(err => {
          console.error('Charge History List Error :: ', err)
          reject(err);
        });
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
