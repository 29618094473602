<template>
  <Layout>
    <section class="csms-dashboard">

      <!-- Title Area Start -->
      <div class="row mb-3 ps-2 pe-2">
        <div class="col-xl-6">
          <div class="font-size-20 fw-bold">DASHBOARD</div>
        </div>
        <div class="col-xl-6 font-size-18 fw-bold">
          <div class="d-flex float-end align-items-center">
            <div class="me-2">
              <div class="avatar-sm">
                <div class="avatar-title bg-soft-primary text-primary rounded-circle">
                  <i class="uil uil-calender"></i>
                </div>
              </div>
            </div>
            <div class="text-black-50 me-2">Today</div>
            <div>{{ nowDateTime }}</div>
          </div>
        </div>
      </div>
      <!-- Title Area End -->

      <!-- Contents Area Start -->
      <div class="row" v-if="!$_utils.isEmpty(dataItems)">

        <!-- Contents Left Area Start -->
        <div class="col-xl-8">

          <!-- Left Top Area Start -->
          <div class="row">
            <div class="col-xl-3">
              <db-summary-card image="dashboard1.png"
                               :val="$_utils.comma(dataItems.memberCount)"
                               :title="$t('dashboard.user')" />
            </div>
            <div class="col-xl-3">
              <db-summary-card image="dashboard2.png"
                               :val="$_utils.comma(dataItems.chargePlaceCount)"
                               :title="$t('dashboard.totalCp')" />
            </div>
            <div class="col-xl-3">
              <db-summary-card image="dashboard3.png"
                               :val="$_utils.comma(dataItems.todayChargeEnergyKwh)"
                               val-unit="kWh"
                               :title="$t('dashboard.todayChargeEnergy')" />
            </div>
            <div class="col-xl-3">
              <db-summary-card image="dashboard4.png"
                               :val="dataItems.todayChargeTime.split(':')[0]"
                               :val-unit="$t('common.hour')"
                               :sub-val="dataItems.todayChargeTime.split(':')[1]"
                               :sub-val-unit="$t('common.minute')"
                               :title="$t('dashboard.todayChargeTime')" />
            </div>
          </div>
          <!-- Left Top Area End -->

          <!-- Left Middle Area Start -->
          <div class="row">
            <div class="col-xl-6">
              <div class="card rounded-4" style="height: 128px">
                <div class="card-body">
                  <div class="mb-2">
                    <div class="d-flex align-items-center">
                      <div class="me-2">
                        <b-img :src="require('@/assets/images/dashboard/dashboard5.png')"
                               class="avatar-sm h-auto rounded-circle" />
                      </div>
                      <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.ctStatus') }}</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-around ps-2 pe-2">
                    <div class="text-center">
                      <div class="font-size-18 fw-bold">{{ $_utils.comma(statusItems.charging) }}</div>
                      <div class="font-size-14 text-black-50">{{ $t('common.statusCharging') }}</div>
                    </div>
                    <div class="ct-right-border-divider" />
                    <div class="text-center">
                      <div class="font-size-18 fw-bold">{{ $_utils.comma(statusItems.available) }}</div>
                      <div class="font-size-14 text-black-50">{{ $t('common.statusAvailable') }}</div>
                    </div>
                    <div class="ct-right-border-divider" />
                    <div class="text-center">
                      <div class="font-size-18 fw-bold">{{ $_utils.comma(statusItems.checking) }}</div>
                      <div class="font-size-14 text-black-50">{{ $t('common.statusChecking') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="card card-h-100 rounded-4">
                <div class="card-body">
                  <div class="mb-2">
                    <div class="d-flex align-items-center">
                      <div class="me-2">
                        <b-img :src="require('@/assets/images/dashboard/dashboard6.png')"
                               class="avatar-sm h-auto rounded-circle" />
                      </div>
                      <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.paymentStatus') }}</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-around ps-2 pe-2">
                    <div class="text-center">
                      <div class="font-size-18 fw-bold">{{ $_utils.comma(dataItems.paymentCount) }}</div>
                      <div class="font-size-14 text-black-50">{{ $t('dashboard.paymentCount') }}</div>
                    </div>
                    <div class="ct-right-border-divider" />
                    <div class="text-center">
                      <div class="font-size-18 fw-bold">{{ $_utils.comma(dataItems.totalChargeFee) }}</div>
                      <div class="font-size-14 text-black-50">{{ $t('dashboard.paymentAmount') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Left Middle Area End -->

          <!-- Left Bottom Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card rounded-4" style="height: 536px">
                <div class="card-body">
                  <div class="row h-100">
                    <div class="col-xl-6">
                      <div class="mb-3">
                        <div class="d-flex align-items-center">
                          <div class="me-2">
                            <b-img :src="require('@/assets/images/dashboard/dashboard7.png')"
                                   class="avatar-sm h-auto rounded-circle" />
                          </div>
                          <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.cpDistribution') }}</div>
                        </div>
                      </div>
                      <div>
                        <GmapMap ref="mapRef"
                                 :center="center"
                                 :zoom="zoom"
                                 :options="{
                                   zoomControl: true,
                                   mapTypeControl: false,
                                   scaleControl: false,
                                   streetViewControl: false,
                                   rotateControl: true,
                                   fullscreenControl: false,
                                   disableDefaultUi: false
                                 }"
                                 style="height: 436px; width: 100%">
                          <gmap-marker :key="index"
                                       v-for="(marker, index) in markers"
                                       :position="marker.position"
                                       @click="selectMarker(marker)">
                            <GmapInfoWindow v-if="marker.showLabel"
                                            :options="{ disableAutoPan: false }"
                                            :position="marker.position"
                                            @closeclick="deselectMarker(marker)">
                              <div class="fw-bold">{{ marker.label }}
                                <i class="mdi mdi-close-circle ms-1" @click="deselectMarker(marker)"></i></div>
                            </GmapInfoWindow>
                          </gmap-marker>
                        </GmapMap>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="mb-3">
                        <div class="mt-2 ms-2">
                          <div class="font-size-16 text-truncate mb-0">
                            <span>{{ $t('dashboard.cpSalesRanking') }}</span>
                            <span class="ms-1">({{ $t('dashboard.last7days') }})</span>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive" style="max-height: 436px;">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr v-for="(item, index) of periodDataItems" :key="index">
                            <td style="width: 30%">{{ $_utils.textLengthOverCut(item.placeName, 10, null) }}</td>
                            <td style="width: 70%" class="mt-1">
                              <b-progress :max="periodDataItemsMax" height="1.5rem">
                                <b-progress-bar :value="item.chargeFee">
                                  <span>¥ <strong>{{ $_utils.comma(item.chargeFee) }}</strong></span>
                                </b-progress-bar>
                              </b-progress>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Left Bottom Area End -->

        </div>
        <!-- Contents Left Area End -->

        <!-- Contents Right Area Start -->
        <div class="col-xl-4">

          <!-- Right Top Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card rounded-4" style="height: 258px">
                <div class="card-body">
                  <div class="mb-3">
                    <div class="d-flex align-items-center">
                      <div class="me-2">
                        <b-img :src="require('@/assets/images/dashboard/dashboard8.png')"
                               class="avatar-sm h-auto rounded-circle" />
                      </div>
                      <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.chargingEquipmentStatus') }}</div>
                      <div class="font-size-24 ms-3">
                        <span class="fw-bold">{{ $_utils.comma(dataItems.chargePointTotalCount) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-7 align-content-center">
                      <div class="table-responsive" style="max-height: 180px;">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr v-for="(item, index) of equipmentItems" :key="index">
                            <td class="col-auto p-1">
                              <i class="fas fa-circle align-middle me-2"
                                 :class="item.type === 'SLOW' ? 'text-primary' : 'text-danger'" />{{ item.name }}
                            </td>
                            <td class="col-auto p-1">
                              <div class="font-size-16 fw-bold text-end">
                                <span>{{ item.rate }}</span>
                                <span class="font-size-14 text-black-50">%</span>
                                <span class="ps-1">({{ item.count }})</span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-xl-5">
                      <apexchart class="apex-charts" height="180" dir="ltr"
                                 :series="chartOptions1.series" :options="chartOptions1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Right Top Area End -->

          <!-- Right Middle Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card rounded-4" style="height: 258px">
                <div class="card-body">
                  <div class="mb-3">
                    <div class="d-flex align-items-center">
                      <div class="me-2">
                        <b-img :src="require('@/assets/images/dashboard/dashboard9.png')"
                               class="avatar-sm h-auto rounded-circle" />
                      </div>
                      <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.chargingProduct') }}</div>
                      <div class="font-size-24 ms-3">
                        <span class="fw-bold">{{ $_utils.comma(dataItems.memberCount) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-7 align-content-center">
                      <div class="table-responsive" style="max-height: 180px;">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr v-for="(item, index) of productItems" :key="index">
                            <td class="col-auto p-1">
                              <i class="fas fa-circle align-middle me-2"
                                 :class="item.type === 'normal' ? 'text-warning' : 'text-success'" />{{ item.name }}
                            </td>
                            <td class="col-auto p-1">
                              <div class="font-size-16 fw-bold text-end">
                                <span>{{ item.rate }}</span>
                                <span class="font-size-14 text-black-50">%</span>
                                <span class="ps-1">({{ item.count }})</span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-xl-5">
                      <apexchart class="apex-charts" height="180" dir="ltr"
                                 :series="chartOptions2.series" :options="chartOptions2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Right Middle Area End -->

          <!-- Right Bottom Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card rounded-4" style="height: 258px">
                <div class="card-body">
                  <div class="mb-3">
                    <div class="d-flex align-items-center">
                      <div class="me-2">
                        <b-img :src="require('@/assets/images/dashboard/dashboard10.png')"
                               class="avatar-sm h-auto rounded-circle" />
                      </div>
                      <div class="font-size-16 text-truncate mb-0">{{ $t('dashboard.paymentHistory') }}</div>
                      <div class="font-size-16 text-truncate mb-0 ps-1">({{ $t('dashboard.last7days') }})</div>
                    </div>
                  </div>

                  <div class="row">
                    <apexchart type="bar" height="180"
                               :series="chartOptions3.series" :options="chartOptions3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Contents Right Area End -->

      </div>
      <!-- Contents Area End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import DbSummaryCard from '@/views/pages/dashboards/components/DbSummaryCard.vue';

export default {
  components: {
    Layout, PageHeader, DbSummaryCard
  },
  data() {
    return {
      mapInitialized: false,
      nowDateTime: null,
      dataItems: null,
      periodDataItems: null,
      periodDataItemsMax: 100,
      statusItems: {
        charging: 0,
        available: 0,
        checking: 0
      },
      center: { lat: 35.6860, lng: 139.7549 }, //도쿄
      zoom: 5,
      markers: [],
      equipmentItems: [],
      chartOptions1: {
        chart: {
          height: 180,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%'
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            formatter: val => `${val.toLocaleString()}기`,
          },
        },
        series: [],
        labels: [],
        //colors: ["#038edc", "#f5f6f8", "#5fd0f3"],
        legend: {
          show: false
        },
      },
      productItems: [],
      chartOptions2: {
        chart: {
          height: 180,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%'
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            formatter: val => `${val.toLocaleString()}개`,
          },
        },
        series: [],
        labels: [],
        //colors: ["#038edc", "#f5f6f8", "#5fd0f3"],
        legend: {
          show: false
        },
      },
      chartOptions3: {
        chart: {
          height: 180,
          type: 'bar'
        },
        //colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            formatter: val => `¥ ${val.toLocaleString()}`,
          },
        },
        series: [{
          name: '',
          data: []
        }],
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              //colors: colors,
              fontSize: '12px'
            }
          }
        }
      }
    }
  },
  mounted() {
    const vm = this;
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    async getItems() {
      const vm = this;

      vm.showOverlay();

      vm.nowDateTime = this.$_utils.nowDate() + ' (' + this.$_utils.nowDay() + ') ' + this.$_utils.nowTime();

      await Promise.all([
        vm.getDataItems(),
        vm.getPeriodDataItems()
      ]);

      vm.hideOverlay();
    },
    getDataItems() {
      const vm = this;
      const _url = '/onmapi/view/Dashboard/data';

      return new Promise((resolve, reject) => {
        http.get(_url).then(res => {
          vm.dataItems = res.data.item;

          let _charging = 0;
          let _available = 0;
          let _checking = 0;

          vm.dataItems.statusItems.forEach(item => {
            if (item.status === 'Charging') {
              _charging += item.count;
            } else if (item.status === 'Available') {
              _available += item.count;
            } else {
              _checking += item.count;
            }
          })

          vm.statusItems = {
            charging: _charging,
            available: _available,
            checking: _checking
          };

          vm.equipmentItems = [];

          if (vm.dataItems.slowDetailCount.length > 0) {
            vm.dataItems.slowDetailCount.forEach(item => {
              vm.equipmentItems.push({
                type: item.chargerType,
                name: `${i18n.t('common.slow')}-${item.maxKw}kW`,
                count: item.count,
                rate: ((item.count/vm.dataItems.chargePointTotalCount)*100).toFixed(1)
              });
            })
          }

          if (vm.dataItems.fastDetailCount.length > 0) {
            vm.dataItems.fastDetailCount.forEach(item => {
              vm.equipmentItems.push({
                type: item.chargerType,
                name: `${i18n.t('common.fast')}-${item.maxKw}kW`,
                count: item.count,
                rate: ((item.count/vm.dataItems.chargePointTotalCount)*100).toFixed(1)
              });
            })
          }

          if (vm.equipmentItems.length > 0) {
            let series = [];
            let labels = [];
            vm.equipmentItems.forEach(item => {
              series.push(item.count);
              labels.push(item.name);
            })
            vm.chartOptions1.series = series;
            vm.chartOptions1.labels = labels;
          }

          vm.productItems = [];

          vm.productItems.push({
            type: 'normal',
            name: i18n.t('common.generalPlan'),
            count: vm.dataItems.memberPlan.normalCount,
            rate: ((vm.dataItems.memberPlan.normalCount/vm.dataItems.memberCount)*100).toFixed(1)
          });

          vm.productItems.push({
            type: 'ultra',
            name: i18n.t('common.ultraPlan'),
            count: vm.dataItems.memberPlan.ultraCount,
            rate: ((vm.dataItems.memberPlan.ultraCount/vm.dataItems.memberCount)*100).toFixed(1)
          });

          if (vm.productItems.length > 0) {
            let series = [];
            let labels = [];
            vm.productItems.forEach(item => {
              series.push(item.count);
              labels.push(item.name);
            })
            vm.chartOptions2.series = series;
            vm.chartOptions2.labels = labels;
          }

          if (vm.dataItems.paymentDetailsByDay.length > 0) {
            let series = [];
            let labels = [];

            vm.dataItems.paymentDetailsByDay.forEach(item => {
              series.push(item.chargeFee);
              labels.push(item.paymentDate);
            })

            vm.chartOptions3.series[0].data = series;
            vm.chartOptions3.xaxis.categories = labels;
          }

          if (vm.dataItems.chargePlaces && vm.dataItems.chargePlaces.length > 0) {
            let markers = [];

            vm.dataItems.chargePlaces.forEach(item => {
              markers.push({ position: { lat: item.lat, lng: item.lng }, showLabel: false, label: item.placeName });
            })

            vm.markers = markers;
          }
          this.$nextTick(() => {
            this.initializeMap();
          });

          resolve(res);
        }).catch(err => {
          console.error('Dashboard Data Items Error :: ', err)
          reject(err);
        });
      });
    },
    initializeMap() {
      const vm = this;
      if (!vm.mapInitialized && vm.markers.length > 0) {
        this.$refs.mapRef.$mapPromise.then((map) => {

          /*global google*/
          const bounds = new google.maps.LatLngBounds();
          vm.markers.forEach(marker => {
            bounds.extend(marker.position);
          });
          map.fitBounds(bounds);
          vm.mapInitialized = true;
        });
      }
    },
    getPeriodDataItems() {
      const vm = this;
      const _url = '/onmapi/view/Dashboard/periodData?period=weekly';

      return new Promise((resolve, reject) => {
        http.get(_url).then(res => {
          vm.periodDataItems = res.data.item.salesRank;

          if (vm.periodDataItems.length > 0) {
            let maxValue = vm.periodDataItems.reduce((max, item) => Math.max(max, item.chargeFee), -Infinity);
            vm.periodDataItemsMax = maxValue * 1.1;
          }
          resolve(res);
        }).catch(err => {
          console.error('Dashboard Period Data Items Error :: ', err)
          reject(err);
        });
      });
    },
    selectMarker(marker) {
      marker.showLabel = true;
    },
    deselectMarker(marker) {
      marker.showLabel = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .gm-ui-hover-effect {
    display: none !important;
  }
}
</style>