<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">거래명세서</div>
    </template>


    <div ref="pdfarea">
    <div class="row">
        <div class="col-xl-12">
          <div class="d-flex justify-content-between">
            <span class="ion-modal-form-group-title font-size-20">거래명세서</span>
            <span>NO.1234567</span>
          </div>
          <hr class="border-primary border-5"/>

          <div class="row">
            <div class="col-xl-6">
              <div class="font-size-16 fw-semibold mb-2">
                공급받는 자
              </div>
              <div class="card shadow-none">
                <div class="card-body">
                  <div v-for="(item, idx) of [
                      { title: '등록번호', value: '12345-789' },
                      { title: '사업자', value: '컴퍼니' },
                      { title: '등록번호', value: '12345-789' },
                    ]" class="row" :key="idx">
                    <div class="col-3">
                      <span class="font-size-14 fw-semibold mb-2">{{ item.title }}</span>
                    </div>
                    <div class="col-9">{{ item.value }}</div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="font-size-16 fw-semibold mb-2">
                공급자
              </div>
              <div class="card shadow-none">
                <div class="card-body">

                  <div v-for="(item, idx) of [
                      { title: '등록번호', value: '12345-789' },
                      { title: '사업자', value: '컴퍼니' },
                      { title: '등록번호', value: '12345-789' },
                    ]" class="row" :key="idx">
                    <div class="col-3">
                      <span class="font-size-14 fw-semibold mb-2">{{ item.title }}</span>
                    </div>
                    <div class="col-9">{{ item.value }}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div class="font-size-16 fw-semibold mb-2">
                  총 결제 금액
                </div>
                <span>
                  (금액 단위: )
                </span>
              </div>

              <table class="w-100 invoice-table">
                <thead>
                <tr>
                  <th>항목</th>
                  <th>금액</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in paymentItems" :key="i">
                  <td>
                    {{ item.type }}
                  </td>
                  <td>
                    {{ item.amount }}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>총 매출액</td>
                    <td>123</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">

              <!-- Table ver1 -->
              <div>
                <div class="d-flex justify-content-between">
                  <div class="font-size-16 fw-semibold mb-2">
                    수수료
                  </div>

                  <div>
                    <span class="me-2">(금액 단위: )</span>
                    <b-button class="exclude-from-pdf" size="sm" variant="outline-warning" v-if="!isCommissionEditMode" @click="startEditMode">수정</b-button>
                    <b-button size="sm me-2" variant="success" v-if="isCommissionEditMode" @click="saveEditItems">저장</b-button>
                    <b-button size="sm" variant="secondary" v-if="isCommissionEditMode" @click="cancelEditMode">취소</b-button>
                  </div>
                </div>

                <table class="w-100 invoice-table">
                  <thead>
                  <tr>
                    <th>항목</th>
                    <th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in (!isCommissionEditMode ? commissionItems : commissionEditItems)" :key="i">
                    <td>
                      <b-form-input
                        v-if="isCommissionEditMode"
                        v-model="item.type"
                        class="text-center"
                      />
                      <span v-else>{{ item.type }}</span>
                    </td>
                    <td>
                      <div class="row align-items-center">

                        <div class="col text-center">
                          <b-form-input
                              v-if="isCommissionEditMode"
                              v-model="item.amount"
                              class="text-center"
                          />
                          <span v-else>{{ item.amount }}</span>
                        </div>
                        <div v-if="isCommissionEditMode" class="col-auto text-center">
                          <b-button variant="outline-danger" size="sm" @click="deleteRow(i)">삭제</b-button>
                          <b-button variant="outline-success" size="sm" @click="addRow(i)">추가</b-button>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td>수수료 VAT (10%)</td>
                    <td></td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr>
                    <td>총 수수료</td>
                    <td>123</td>
                  </tr>
                  </tfoot>
                </table>
              </div>


              <!-- Table ver2 -->
<!--              <div>
                <div class="text-end mt-3">
                <span class="me-2">
                    (금액 단위: )
                </span>
                  <b-button v-if="isEditing2" @click="saveChanges2" variant="primary">저장</b-button>
                  <b-button v-else @click="startEditing2" variant="secondary">수정</b-button>
                  <b-button v-if="isEditing2" :disabled="!selectedRow2" @click="addRow2" class="ml-2">추가</b-button>
                  <b-button v-if="isEditing2" :disabled="!selectedRow2" @click="deleteRow2" variant="danger" class="ml-2">삭제</b-button>

                </div>

                <b-table
                    class="invoice-table"
                    :key="isEditing2"
                    :items="displayItems2"
                    :fields="fields2"
                    :selectable="isEditing2"
                    select-mode="single"
                    @row-selected="onRowSelected2"
                    ref="selectableTable"
                >
                  <template #cell(type)="data">
                    <b-form-input
                        class="text-center"
                        v-if="isEditing2 && data.item === selectedRow2"
                        v-model="selectedRow2.type"
                    ></b-form-input>
                    <span v-else>{{ data.item.type }}</span>
                  </template>
                  <template #cell(amount)="data">
                    <b-form-input
                        class="text-center"
                        v-if="isEditing2 && data.item === selectedRow2"
                        v-model="selectedRow2.amount"
                        type="number"
                    ></b-form-input>
                    <span v-else>{{ data.item.amount }}</span>
                  </template>
                </b-table>
              </div>-->

            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card shadow-none border-primary">
                <div class="card-body p-3">
                  <div class="text-center">
                    <span class="me-3 font-size-14 fw-semibold">합계 금액</span><span class="font-size-24 fw-semibold text-primary">2,800</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <div class="card shadow-none">
                <div class="card-body">
                  <div class="font-size-14 fw-semibold mb-2">
                    정산 계좌 정보
                  </div>
                  <div>
                    - 은행
                  </div>
                  <div>
                    - 계좌번호
                  </div>
                  <div>
                    - 예금주
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr/>

          <span class="ion-modal-form-group-title font-size-20">매출 세부내역서</span>

          <hr class="border-primary border-5"/>

          <div class="row mb-3">
            <div class="col-xl-12">
              <b-table class="text-nowrap align-middle text-center" bordered hover
                       thead-class="align-middle"
                       :fields="fields" :items="items" />
            </div>
          </div>
        </div>
      </div>
    </div>


    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="exportToPDF" :disabled="!isCommissionEditMode">다운로드</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: "InvoiceModal",
  data() {
    return {
      visible: false,
      //   { key: 'type', label: '항목' },
      //   { key: 'amount', label: '금액' }
      // ],
      // paymentFields: [
      paymentItems: [
        { type: '간편결제', amount: 12345 },
        { type: '카드결제', amount: 23456 }
      ],
      commissionItems: [
        { type: '수수료 항목1', amount: 12345 },
        { type: '수수료 항목2', amount: 23456 }
      ],
      commissionEditItems: null,
      isCommissionEditMode: false,
      fields: [
        { key: '거래일시', label: '거래일시' },
        { key: '품목', label: '품목' },
        { key: '수량', label: '수량' },
        { key: '단가', label: '단가' },
        { key: '세금', label: '세금' },
        { key: '결제금액', label: '결제금액' },
      ],
      items: [
        { 거래일시 : 'data', 품목: 'data', 수량: 'data', 단가: '44', 세금: '23', 결제금액: '1234' },
        { 거래일시 : 'data', 품목: 'data', 수량: 'data', 단가: '44', 세금: '23', 결제금액: '1234' }
      ],

      fields2: [
        { key: 'type', label: '항목' },
        { key: 'amount', label: '금액' }
      ],
      items2: [
        { type: '수수료 항목1', amount: 66666 },
        { type: '수수료 항목2', amount: 777 }
      ],
      displayItems2: [],
      selectedRow2: null,
      isEditing2: false
    }
  },
  //TODO:API호출
  created() {
    this.displayItems2 = [...this.items2];
  },
  methods: {
    show() {
      const vm = this;
      vm.visible = true;
    },
    close() {
      const vm = this;

      // this.$emit('callback');
      vm.visible = false;
      vm.reset();
    },


    exportToPDF() {
      const element = this.$refs.pdfarea;
      const options = {
        margin: 15,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          dpi: 300,
          letterRendering: true,
          ignoreElements: (element) => {
            return element.classList.contains('exclude-from-pdf');
          }
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf()
        .set(options)
        .from(element)
        .save('document.pdf');
    },
    startEditMode() {
      const vm = this;
      vm.isCommissionEditMode = true;
      vm.commissionEditItems = [ ...vm.commissionItems ];
    },
    addRow(i) {
      const vm = this;
      vm.commissionEditItems.splice(i + 1, 0, { type: null, amount: null });
    },
    deleteRow(i) {
      const vm = this;
      vm.commissionEditItems.splice(i, 1);
    },
    saveEditItems() {
      const vm = this;
      vm.commissionItems = vm.commissionEditItems;
      vm.isCommissionEditMode = false;
    },
    cancelEditMode() {
      const vm = this;
      vm.isCommissionEditMode = false;
      vm.commissionEditItems = null;
    },

    //table ver2
    onRowSelected2(items) {
      const vm = this;
      if (items.length > 0) {
        vm.selectedRow2 = items[0]
      } else {
        vm.selectedRow2 = null
      }
    },
    startEditing2() {
      const vm = this;
      vm.isEditing2 = true
      vm.displayItems2 = this.items2
    },
    saveChanges2() {
      const vm = this;
      vm.items2 = vm.displayItems2
      vm.isEditing2 = false
      vm.selectedRow2 = null
    },
    addRow2() {
      const vm = this;
      if (vm.selectedRow2) {
        const newRow = { name: null, age: null }
        const index = vm.displayItems2.indexOf(vm.selectedRow2)
        vm.displayItems2.splice(index + 1, 0, newRow)

        this.$nextTick(() => {
          this.$refs.selectableTable.selectRow(index + 1)
          vm.selectedRow2 = newRow
        })
      }
    },
    deleteRow2() {
      const vm = this;
      if (vm.selectedRow2) {
        const index = vm.displayItems2.indexOf(vm.selectedRow2)
        vm.displayItems2.splice(index, 1)
        vm.selectedRow2 = null
      }
    },
    reset() {
      const vm = this;
      vm.selectedRow2 = null
      vm.isEditing2 = false
      vm.displayItems2 = []
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .invoice-table {
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      width: 50%;
      border: 1px solid white;
      font-weight: normal;
      padding: 8px;
      text-align: center;
      font-size: 0.9em;
      background: #fafafa;
    }
    th {
      font-weight: bold;
      font-size: 1em;
      background: #e4edf9;
      text-align: center;
    }
    tfoot {
      border-top: 2px solid #e4edf9;
      td {
        font-weight: bold;
      }
    }
  }
}
</style>